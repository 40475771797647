import get from "lodash/get"
import isPlainObject from "lodash/isPlainObject"
import mapValues from "lodash/mapValues"
import { AjaxError } from "rxjs/ajax"

const arrayze = (a) => (Array.isArray(a) ? a : [a])

export const transformErrorForForm = (error) => {
  if (error.status === 400) {
    let errorData
    if (error instanceof AjaxError && isPlainObject(error.response)) {
      errorData = error.response
    } else if (isPlainObject(get(error, "response.body"))) {
      errorData = error.response.body
    }
    // TODO: Better joins of errors...
    if (errorData) {
      return mapValues(errorData, (listOfErrors) =>
        arrayze(listOfErrors).join(",")
      )
    }
  }
  // When no 400 (not related 2 form)
  // add a special __noFieldsServerError key \w original error
  return {
    __noFieldsServerError: error,
  }
}
