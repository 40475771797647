import dayjs from "dayjs"
import { useMemo } from "react"
import { Spinner, Table } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Page } from "../../components/Page"
import { SmartPagination } from "../../components/SmartPagination"
import { useUsersList } from "../../hooks/useUser"

export function UsersList() {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = parseInt(searchParams.get("page") || 1, 10)

  const listFilters = useMemo(
    () => ({
      page,
    }),
    [page]
  )

  const [{ list, numPages }] = useUsersList(listFilters)
  const navigate = useNavigate()

  if (list === null) {
    return (
      <div className="d-flex h-100 w-100 align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  return (
    <Page>
      <Page.Header title="Utenti" titleClassName="ps-3">
        <Page.Header.Action
          title="Crea"
          onClick={() => navigate("/users/new")}
        />
      </Page.Header>
      <Page.Body>
        <Table
          striped
          className="mt-3"
          borderless
          style={{
            tableLayout: "fixed",
            "--bs-table-striped-bg": "var(--bs-light)",
          }}
        >
          <thead>
            <tr>
              <th className="ps-3">Nome</th>
              <th>E-mail</th>
              <th>Ultimo Accesso</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((user, index) => (
              <tr key={index}>
                <td className="ps-3">
                  <Link to={`/users/${user.id}`}>
                    {user.full_name || <i>Utente senza nome</i>}
                  </Link>
                </td>
                <td>{user.email}</td>
                <td>
                  {user.last_access
                    ? dayjs(user.last_access).format("DD/MM/YYYY HH:mm")
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex flex-row justify-content-end">
          <SmartPagination
            page={page}
            numPages={numPages}
            onPageChange={(page) => setSearchParams({ page })}
          ></SmartPagination>
        </div>
      </Page.Body>
    </Page>
  )
}
