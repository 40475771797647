import { useFormik } from "formik"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useState } from "react"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthUser } from "use-eazy-auth"
import * as Yup from "yup"
import { LoadingButton } from "../../components/LoadingButton"
import { Page } from "../../components/Page"
import { useUserDetail } from "../../hooks/useUser"
import { transformErrorForForm } from "../../utils"
import { DestroyUserModal } from "./DestroyUserModal"

const UserSchema = Yup.object().shape({
  full_name: Yup.string().required("Nome è un campo obbligatorio"),
  email: Yup.string().required("E-mail è un campo obbligatorio"),
})

function UserForm({ user, create, update, destroy, resendMail }) {
  const [destroyModalState, destroyModalActions] = useModalTrigger()
  const [success, setSuccess] = useState(false)
  const { user: userCurrent } = useAuthUser()

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      full_name: user ? user.full_name : "",
      email: user ? user.email : "",
    },
    validationSchema: UserSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (user) {
        return update
          .onSuccess(() => {
            setSuccess(true)
          })
          .asPromise(user.id, values)
      } else {
        return create
          .asPromise(values)
          .catch((err) => actions.setErrors(transformErrorForForm(err)))
      }
    },
  })

  return (
    <Page>
      <Page.Header
        titleClassName="ps-3"
        title={user ? user.full_name : "Nome Utente"}
      >
        <LoadingButton
          variant="outline-primary"
          className={"mx-2"}
          disabled={user && !formik.dirty}
          onClick={() => {
            user ? formik.resetForm() : navigate(`/users`)
          }}
        >
          Annulla
        </LoadingButton>
        <LoadingButton
          enableSuccess={success}
          variant="primary"
          pending={formik.isSubmitting}
          disabled={!formik.dirty}
          onClick={() => {
            formik.submitForm()
          }}
        >
          Salva
        </LoadingButton>
      </Page.Header>
      <Page.Body>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-3">
                <div className="card-header bg-card-header-sky-blue">
                  Informazioni Utente
                </div>
                <Row className="mb-3 p-3">
                  <Col md={6}>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className="d-flex align-items-center">
                          Nome{" "}
                        </Form.Label>
                        <Form.Control
                          name="full_name"
                          className={
                            formik.errors.full_name &&
                            formik.touched.full_name &&
                            "input-error"
                          }
                          value={formik.values.full_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={"Inserisci Nome"}
                        />
                        {formik.errors.full_name &&
                          formik.touched.full_name && (
                            <span className="text-danger mt-1">
                              {formik.errors.full_name}
                            </span>
                          )}
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className="d-flex align-items-center">
                          E-mail{" "}
                        </Form.Label>
                        <Form.Control
                          name="email"
                          value={formik.values.email}
                          className={
                            formik.errors.email &&
                            formik.touched.email &&
                            "input-error"
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={"Inserisci E-mail"}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <span className="text-danger mt-1">
                            {formik.errors.email}
                          </span>
                        )}
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {user && user.id !== userCurrent.id && (
            <div className="text-end mb-5">
              <Button
                variant="danger"
                onClick={() => {
                  destroyModalActions.open("dummy")
                }}
              >
                Elimina
              </Button>
            </div>
          )}
        </Form>
      </Page.Body>
      {destroyModalState.value !== null && (
        <DestroyUserModal
          isOpen={destroyModalState.isOpen}
          id={destroyModalState.value}
          onCancel={destroyModalActions.close}
          onClosed={destroyModalActions.onClosed}
          onSubmit={(result) => {
            destroy
              .onSuccess(() => {
                destroyModalActions.close()
                navigate("/users", { replace: true })
              })
              .run(user.id)
          }}
        />
      )}
    </Page>
  )
}

export function UserDetail() {
  const { id } = useParams()
  const [{ data: user }, { create, update, destroy, resendMail }] =
    useUserDetail(id)

  if (!user && id) {
    return (
      <div className="d-flex h-100 w-100 align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }
  return (
    <UserForm
      user={user}
      id={id}
      destroy={destroy}
      create={create}
      update={update}
      resendMail={resendMail}
    />
  )
}
