import omit from "lodash/omit"
import { Collection } from "ol"
import GeoJSON from "ol/format/GeoJSON"
import Point from "ol/geom/Point"
import Draw, { createBox } from "ol/interaction/Draw"
import Modify from "ol/interaction/Modify"
import Select from "ol/interaction/Select"
import ImageLayer from "ol/layer/Image"
import VectorLayer from "ol/layer/Vector"
import OlMap from "ol/Map"
import Projection from "ol/proj/Projection"
import Static from "ol/source/ImageStatic"
import VectorSource from "ol/source/Vector"
import CircleStyle from "ol/style/Circle"
import Fill from "ol/style/Fill"
import Stroke from "ol/style/Stroke"
import Style from "ol/style/Style"
import Text from "ol/style/Text"
import OlView from "ol/View"
import { Delaunay } from "d3-delaunay"

const limitStyle = [
  new Style({
    stroke: new Stroke({
      color: "#0DCAF0",
      width: 4,
    }),
  }),
]

const roomsStyle = (map) => (feature) => {
  const z = map.getView().getZoom()
  const txt = feature.getProperties().index
  const result = [
    new Style({
      stroke: new Stroke({
        color: "#FFC107",
        width: 4,
      }),
    }),
  ]
  if (txt !== undefined) {
    result.push(
      new Style({
        image: new CircleStyle({
          radius: 8 + (z - 16) * 7,
          fill: new Fill({
            color: "#FFC107",
          }),
        }),
        geometry: function (feature) {
          // return the coordinates of the first ring of the polygon
          const [x, y] = feature.getGeometry().getCoordinates()[0][0]
          return new Point([x + 30, y + 30])
        },
        text: new Text({
          text: txt.toString(),
          fill: new Fill({ color: "white" }),
          font: `bold ${10 + (z - 16) * 7}px/1 sans-serif`,
        }),
      })
    )
  }
  return result
}

const beaconStyle = (feature) => {
  const txt = feature.getProperties().minor
  const result = [
    new Style({
      image: new CircleStyle({
        radius: 8,
        fill: new Fill({
          color: "#DC3545",
        }),
      }),
    }),
  ]
  if (txt !== undefined) {
    result.push(
      new Style({
        text: new Text({
          text: txt.toString(),
          fill: new Fill({ color: "black" }),
          textAlign: "center",
          font: `bold 13px/1 sans-serif`,
          offsetY: -16,
        }),
      })
    )
  }
  return result
}

const voronoiStyle = [
  new Style({
    stroke: new Stroke({
      color: "#0DCAF0",
      width: 2,
    }),
  }),
]

export class ImperativeMap {
  constructor(containerRef) {
    this.serializer = new GeoJSON()

    this.bgLayer = new ImageLayer()

    this.limitCollection = new Collection()
    const limitSource = new VectorSource({
      wrapX: false,
      features: this.limitCollection,
    })
    this.limitLayer = new VectorLayer({
      source: limitSource,
      style: limitStyle,
    })

    this.roomsCollection = new Collection()
    const roomsSource = new VectorSource({
      wrapX: false,
      features: this.roomsCollection,
    })
    this.roomsLayer = new VectorLayer({
      source: roomsSource,
    })

    this.beaconsCollection = new Collection()
    const beaconsSource = new VectorSource({
      wrapX: false,
      features: this.beaconsCollection,
    })
    this.beaconsLayer = new VectorLayer({
      source: beaconsSource,
      style: beaconStyle,
    })

    this.voronoiCollection = new Collection()
    const voronoiSource = new VectorSource({
      wrapX: false,
      features: this.voronoiCollection,
    })
    this.voronoiLayer = new VectorLayer({
      source: voronoiSource,
      style: voronoiStyle,
    })

    this.drawBoxCollection = new Collection()
    this.drawBox = new Draw({
      features: this.drawBoxCollection,
      type: "Circle",
      geometryFunction: createBox(),
    })
    this.drawBox.setActive(false)

    this.drawPointCollection = new Collection()
    this.drawPoint = new Draw({
      features: this.drawPointCollection,
      type: "Point",
    })
    this.drawPoint.setActive(false)

    this.modifyCollection = new Collection()
    this.modify = new Modify({
      features: this.modifyCollection,
    })
    this.modify.setActive(false)

    this.modifyVoronoiCollection = new Collection()
    this.modifyVoronoi = new Modify({
      features: this.modifyVoronoiCollection,
      style: beaconStyle,
    })
    this.modifyVoronoi.setActive(false)

    this.select = new Select({
      features: this.modifyCollection,
    })
    this.select.setActive(false)

    this.map = new OlMap({
      target: containerRef.current,
      layers: [
        this.bgLayer,
        this.limitLayer,
        this.roomsLayer,
        this.beaconsLayer,
        this.voronoiLayer,
      ],
      view: new OlView({
        center: [0, 0],
        zoom: 16,
        minZoom: 16,
      }),
    })

    this.roomsLayer.setStyle(roomsStyle(this.map))

    this.map.addInteraction(this.drawBox)
    this.map.addInteraction(this.drawPoint)
    this.map.addInteraction(this.modify)
    this.map.addInteraction(this.modifyVoronoi)
    this.map.addInteraction(this.select)
  }

  drawVoronoi() {
    const del = Delaunay.from(
      this.beaconsCollection
        .getArray()
        .map((beacon) => beacon.getGeometry().getCoordinates())
    )
    const limit = this.limitLayer.getSource().getFeatures()[0]
    if (!limit) {
      return
    }
    const ext = limit.getGeometry().getExtent()
    const vor = del.voronoi(ext)
    this.voronoiCollection.clear()
    for (const cell of vor.cellPolygons()) {
      this.voronoiCollection.push(
        this.serializer.readFeature({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [cell],
          },
        })
      )
    }
  }

  getLayer(layerName) {
    switch (layerName) {
      case "background":
        return this.bgLayer
      case "limit":
        return this.limitLayer
      case "rooms":
        return this.roomsLayer
      case "beacons":
        return this.beaconsLayer
      case "voronoi":
        return this.voronoiLayer
      default:
        return null
    }
  }

  toggleLayer(layerName) {
    switch (layerName) {
      case "background":
        this.bgLayer.setVisible(!this.bgLayer.getVisible())
        break
      case "limit":
        this.limitLayer.setVisible(!this.limitLayer.getVisible())
        break
      case "rooms":
        this.roomsLayer.setVisible(!this.roomsLayer.getVisible())
        break
      case "beacons":
        this.beaconsLayer.setVisible(!this.beaconsLayer.getVisible())
        break
      case "voronoi":
        this.voronoiLayer.setVisible(!this.voronoiLayer.getVisible())
        break
      default:
        break
    }
  }

  setBackgroundImage(url, width, height) {
    const extent = [-width / 2, -height / 2, width / 2, height / 2]
    const projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: extent,
    })
    const source = new Static({
      url,
      projection: projection,
      imageExtent: extent,
    })
    this.bgLayer.setSource(source)
  }

  setLimit(geometry) {
    const feature = this.serializer.readFeature({
      type: "Feature",
      geometry,
    })
    this.limitCollection.clear()
    this.limitCollection.push(feature)
  }

  setRooms(rooms) {
    const prevFeatures = this.roomsCollection.getArray()
    for (const [n, room] of rooms.entries()) {
      const id = room.id
      const prevFeat = prevFeatures.find((feat) => feat.getId() === id)
      if (prevFeat >= 0) {
        prevFeat.setGeometry(this.serializer.readGeometry(room.geometry))
        prevFeat.setProperties(omit(room, "geometry"))
        prevFeat.setProperties({ index: n + 1 })
      } else {
        const feat = this.serializer.readFeature({
          type: "Feature",
          geometry: room.geometry,
          properties: { ...omit(room, "geometry"), index: n + 1 },
        })
        feat.setId(room.id)
        this.roomsCollection.push(feat)
      }
    }
  }

  setBeacons(beacons) {
    const prevFeatures = this.beaconsCollection.getArray()
    for (const [n, beacon] of beacons.entries()) {
      const id = beacon.id
      const prevFeat = prevFeatures.find((feat) => feat.getId() === id)
      if (prevFeat >= 0) {
        prevFeat.setGeometry(this.serializer.readGeometry(beacon.geometry))
        prevFeat.setProperties(omit(beacon, "geometry"))
        prevFeat.setProperties({ index: n + 1 })
      } else {
        const feat = this.serializer.readFeature({
          type: "Feature",
          geometry: beacon.geometry,
          properties: { ...omit(beacon, "geometry"), index: n + 1 },
        })
        feat.setId(beacon.id)
        this.beaconsCollection.push(feat)
      }
    }
    this.drawVoronoi()
  }

  enableLimitDraw(onCommitChangeStatus) {
    if (this.limitCollection.getLength() > 0) {
      this.oldFeature = this.limitCollection.item(0)
      this.modifyCollection.push(this.oldFeature)
      onCommitChangeStatus({ allowCommit: true })
    }
    let lastDrawFeature = this.oldFeature
    const drawStartHandler = (e) => {
      this.modifyCollection.clear()
      this.limitCollection.clear()
      onCommitChangeStatus({ allowCommit: false })
    }
    this.drawBox.on("drawstart", drawStartHandler)
    const drawEndHandler = (e) => {
      this.modifyCollection.push(e.feature)
      this.limitCollection.push(e.feature)
      lastDrawFeature = e.feature
      onCommitChangeStatus({ allowCommit: true })
    }
    this.drawBox.on("drawend", drawEndHandler)
    const drawAbortHandler = () => {
      this.drawBoxCollection.clear()
      this.modifyCollection.clear()
      if (lastDrawFeature) {
        this.limitCollection.push(lastDrawFeature)
        this.modifyCollection.push(lastDrawFeature)
        onCommitChangeStatus({ allowCommit: true })
      }
    }
    this.drawBox.on("drawabort", drawAbortHandler)
    this.drawBox.setActive(true)
    this.modify.setActive(true)
    const tearDown = () => {
      this.drawBox.un("drawstart", drawStartHandler)
      this.drawBox.un("drawend", drawEndHandler)
      this.drawBox.un("drawabort", drawAbortHandler)
      this.drawBox.setActive(false)
      this.modify.setActive(false)
      this.drawBoxCollection.clear()
      this.modifyCollection.clear()
      this.oldFeature = null
    }
    const commit = () => {
      if (!lastDrawFeature) {
        throw new Error("Cannot commit without a feature!")
      }
      let ret = this.serializer.writeGeometryObject(
        lastDrawFeature.getGeometry()
      )
      tearDown()
      return ret
    }
    const reject = () => {
      this.limitCollection.clear()
      if (this.oldFeature) {
        this.limitCollection.push(this.oldFeature)
      }
      tearDown()
    }

    return { commit, reject }
  }

  enableRoomDraw(onCommitChangeStatus, roomId) {
    const editFeature = roomId
      ? this.roomsLayer.getSource().getFeatureById(roomId)
      : null
    if (editFeature) {
      this.oldFeature = editFeature.clone()
      this.modifyCollection.push(editFeature)
      onCommitChangeStatus({ allowCommit: true })
    }
    let lastDrawFeature = editFeature

    const drawStartHandler = (e) => {
      this.modifyCollection.clear()
      this.roomsCollection.remove(lastDrawFeature)
      onCommitChangeStatus({ allowCommit: false })
    }
    this.drawBox.on("drawstart", drawStartHandler)

    const drawEndHandler = (e) => {
      if (lastDrawFeature) {
        e.feature.setProperties(
          omit(lastDrawFeature.getProperties(), "geometry")
        )
      }
      this.modifyCollection.push(e.feature)
      this.roomsCollection.push(e.feature)
      lastDrawFeature = e.feature
      onCommitChangeStatus({ allowCommit: true })
    }
    this.drawBox.on("drawend", drawEndHandler)

    const drawAbortHandler = () => {
      this.drawBoxCollection.clear()
      this.modifyCollection.clear()
      if (lastDrawFeature) {
        this.roomsCollection.push(lastDrawFeature)
        this.modifyCollection.push(lastDrawFeature)
        onCommitChangeStatus({ allowCommit: true })
      }
    }
    this.drawBox.on("drawabort", drawAbortHandler)
    this.drawBox.setActive(true)
    this.modify.setActive(true)

    const tearDown = () => {
      this.drawBox.un("drawstart", drawStartHandler)
      this.drawBox.un("drawend", drawEndHandler)
      this.drawBox.un("drawabort", drawAbortHandler)
      this.drawBox.setActive(false)
      this.modify.setActive(false)
      this.drawBoxCollection.clear()
      this.modifyCollection.clear()
    }
    const commit = () => {
      if (!lastDrawFeature) {
        throw new Error("Cannot commit without a feature!")
      }
      let ret = this.serializer.writeGeometryObject(
        lastDrawFeature.getGeometry()
      )
      tearDown()
      return ret
    }
    const reject = () => {
      tearDown()
      this.roomsCollection.remove(lastDrawFeature)
      if (this.oldFeature) {
        this.oldFeature.setId(lastDrawFeature.getId())
        this.roomsCollection.push(this.oldFeature)
      }
    }

    return { commit, reject }
  }

  enableBeaconDraw(onCommitChangeStatus, beaconId) {
    const boundDrawVoronoi = this.drawVoronoi.bind(this)
    const editFeature = beaconId
      ? this.beaconsLayer.getSource().getFeatureById(beaconId)
      : null
    if (editFeature) {
      this.oldFeature = editFeature.clone()
      this.modifyCollection.push(editFeature)
      onCommitChangeStatus({ allowCommit: true })
      editFeature.on("change", boundDrawVoronoi)
    }
    let lastDrawFeature = editFeature

    const drawStartHandler = (e) => {
      this.modifyCollection.clear()
      this.beaconsCollection.remove(lastDrawFeature)
      onCommitChangeStatus({ allowCommit: false })
    }
    this.drawPoint.on("drawstart", drawStartHandler)

    const drawEndHandler = (e) => {
      if (lastDrawFeature) {
        e.feature.setProperties(
          omit(lastDrawFeature.getProperties(), "geometry")
        )
        lastDrawFeature.un("change", boundDrawVoronoi)
      }
      this.modifyCollection.push(e.feature)
      this.beaconsCollection.push(e.feature)
      lastDrawFeature = e.feature
      lastDrawFeature.on("change", boundDrawVoronoi)
      onCommitChangeStatus({ allowCommit: true })
      boundDrawVoronoi()
    }
    this.drawPoint.on("drawend", drawEndHandler)

    const drawAbortHandler = () => {
      this.drawPointCollection.clear()
      this.modifyCollection.clear()
      if (lastDrawFeature) {
        this.beaconsCollection.push(lastDrawFeature)
        this.modifyCollection.push(lastDrawFeature)
        onCommitChangeStatus({ allowCommit: true })
      }
    }
    this.drawPoint.on("drawabort", drawAbortHandler)
    this.drawPoint.setActive(true)
    this.modify.setActive(true)

    const tearDown = () => {
      this.drawPoint.un("drawstart", drawStartHandler)
      this.drawPoint.un("drawend", drawEndHandler)
      this.drawPoint.un("drawabort", drawAbortHandler)
      this.drawPoint.setActive(false)
      this.modify.setActive(false)
      this.drawPointCollection.clear()
      this.modifyCollection.clear()
      lastDrawFeature.un("change", this.drawVoronoi)
    }
    const commit = () => {
      if (!lastDrawFeature) {
        throw new Error("Cannot commit without a feature!")
      }
      let ret = this.serializer.writeGeometryObject(
        lastDrawFeature.getGeometry()
      )
      tearDown()
      return ret
    }
    const reject = () => {
      tearDown()
      this.beaconsCollection.remove(lastDrawFeature)
      if (this.oldFeature) {
        this.oldFeature.setId(lastDrawFeature.getId())
        this.beaconsCollection.push(this.oldFeature)
      }
    }

    return { commit, reject }
  }

  enableVoronoiDraw() {
    const boundDrawVoronoi = this.drawVoronoi.bind(this)

    this.modifyVoronoi.setActive(true)

    for (const feat of this.beaconsLayer.getSource().getFeatures()) {
      feat.on("change", boundDrawVoronoi)
      this.modifyVoronoiCollection.push(feat)
    }

    const tearDown = () => {
      this.modifyVoronoi.setActive(false)
      this.modifyVoronoiCollection.clear()
      for (const feat of this.beaconsLayer.getSource().getFeatures()) {
        feat.un("change", boundDrawVoronoi)
      }
    }
    const commit = () => {
      const features = this.beaconsLayer.getSource().getFeatures()
      const data = features.map((feat) => ({
        ...feat.getProperties(),
        id: feat.getId(),
        geometry: this.serializer.writeGeometryObject(feat.getGeometry()),
      }))
      tearDown()
      return data
    }
    const reject = () => {
      tearDown()
    }

    return { commit, reject }
  }
}
