import { useState } from "react"
import { Form, Modal, Button, Spinner } from "react-bootstrap"

export function CreateIndoorMapModal({
  loading,
  isOpen,
  onClosed,
  onSubmit,
  onCancel,
}) {
  const [name, setName] = useState("")

  return (
    <Modal show={isOpen} onHide={onCancel} onExited={onClosed}>
      <Modal.Header closeButton>Mappa</Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit(name)
            return false
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Inserisci il nome della mappa</Form.Label>
            <Form.Control
              type="text"
              placeholder="Mappa - PIANO 1"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-end align-items-center">
        <Button
          onClick={() => {
            onSubmit(name)
          }}
        >
          {loading && <Spinner size="sm" className="me-1" />}
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
