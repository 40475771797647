import { useFormik } from "formik"
import { useEffect } from "react"
import { Alert, Button, Form } from "react-bootstrap"
import { useAuthActions, useAuthState } from "use-eazy-auth"

export function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values, action) => {
      login({ email: values.email, password: values.password })
    },
  })

  useEffect(() => {
    clearLoginError()
  }, [clearLoginError])

  return (
    <div className="row h-100">
      <div className="position-absolute title-login">Beacon Maps Manager</div>
      <div className="col-md-6 offset-md-3 position-relative">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh", width: "100%" }}
        >
          <div style={{ width: "70%" }}>
            <h3 className="mb-5">Login</h3>
            <Form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
              </div>
              <div className="mb-3">
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
              </div>
              <Button
                className="w-100 mt-4"
                type="submit"
                disabled={loginLoading}
              >
                {!loginLoading ? "Login" : "Logged in..."}
              </Button>
              {loginError && (
                <Alert
                  className="mt-4 position-absolute"
                  variant="danger"
                  style={{ bottom: 10, width: "67.5%" }}
                >
                  Email/password errati
                </Alert>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
