import { useState, useRef } from "react"
import { Modal, Button, ButtonGroup } from "react-bootstrap"
import { LoadingButton } from "../../components/LoadingButton"

export function PickImageModal({
  isOpen,
  loading,
  onConfirm,
  onCancel,
  onClosed,
}) {
  const [file, setFile] = useState("")
  const inputRef = useRef()

  return (
    <Modal show={isOpen} onHide={onCancel} onExited={onClosed}>
      <Modal.Header closeButton>Inserisci immagine</Modal.Header>
      <Modal.Body>
        <input
          type="file"
          ref={inputRef}
          className="d-none"
          onChange={(e) => setFile(e.target.files.item(0))}
        />
        <ButtonGroup className="border-dedede border rounded w-100 d-flex flex-row">
          <Button
            variant=""
            onClick={() => inputRef.current.click()}
            className="flex-0 p bg-gray-200 text-start button-no-outline"
          >
            Seleziona file
          </Button>
          <Button
            variant="white"
            onClick={() => inputRef.current.click()}
            className="flex-1 text-start button-no-outline"
          >
            {file && file.name}
            {!file && (
              <span className="text-secondary">Nessun file selezionato</span>
            )}
          </Button>
        </ButtonGroup>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-end align-items-center">
        <LoadingButton
          pending={loading}
          enableSuccess={false}
          onClick={() => {
            onConfirm(file)
          }}
        >
          Conferma
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}
