import { rj, useRunRj, useRj } from "react-rocketjump"
import { api } from "../api"
import rjList from "react-rocketjump/plugins/plainList"
import { keyBy } from "lodash"

const liststate = rj(rjList(), {
  effectCaller: "configured",
  effect: (token) => (filters) => {
    return api.auth(token).get("/indoor/beacon", filters)
  },
  computed: {
    list: "getData",
    pending: "isPending",
    error: "getError",
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/indoor/beacon/${id}/`, data)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.map((item) =>
            item.id === result.id ? result : item
          ),
        }
      },
    }),
    updateBatch: rj.mutation.single({
      effect: (token) => (data) => {
        return api.auth(token).put(`/indoor/beacon/bulk_update/`, data)
      },
      updater: (state, result) => {
        const d = keyBy(result, "id")
        return {
          ...state,
          data: state.data.map((datum) =>
            d[datum.id] ? { ...datum, ...d[datum.id] } : datum
          ),
        }
      },
    }),
  },
})

export function useIndoorBeaconList(params) {
  return useRunRj(liststate, [params], false)
}

const createstate = rj({
  effectCaller: "configured",
  effect: (token) => (data) => {
    return api.auth(token).post("/indoor/beacon/", data)
  },
})

export function useIndoorBeaconCreate() {
  return useRj(createstate)
}

const detailstate = rj({
  effectCaller: "configured",
  effect: (token) => (id) => {
    return api.auth(token).get(`/indoor/beacon/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/indoor/beacon/${id}`, data)
      },
      updater: "updateData",
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => {
        return api.auth(token).delete(`/indoor/beacon/${id}`)
      },
      updater: (state, result) => ({ ...state, data: null }),
    }),
  },
})

export function useIndoorBeacon(id) {
  return useRunRj(detailstate, [id], true)
}
