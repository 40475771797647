import { useLayoutEffect, useState } from "react"
import usePrevious from "magik-react-hooks/usePrevious"
import { Button, Spinner } from "react-bootstrap"
import { FaCheck } from "react-icons/fa"

export function LoadingButton({
  children,
  pending,
  onClick,
  variant,
  enableSuccess = true,
  disabled,
  ...props
}) {
  const [success, setSuccess] = useState(false)
  const wasPending = usePrevious(pending)

  useLayoutEffect(() => {
    if (wasPending === true && pending === false && enableSuccess) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }, [pending, wasPending, enableSuccess])

  const isOutline = variant?.startsWith("outline-")

  const successVariant = isOutline ? "outline-success" : "success"

  return (
    <Button
      variant={success ? successVariant : variant}
      {...props}
      disabled={disabled || success || pending}
      style={{ opacity: success || pending ? 1 : undefined }}
      onClick={onClick}
    >
      <span className="d-inline-flex flex-row align-items-center justify-content-start">
        {pending && !success && (
          <Spinner size="sm" className="me-1" animation="border" role="status" />
        )}
        {success && <FaCheck className="me-1" />}
        {children}
      </span>
    </Button>
  )
}
