import { useFormik } from "formik"
import { Button, Form, Modal } from "react-bootstrap"
import * as Yup from "yup"
import { LoadingButton } from "../../components/LoadingButton"

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required(
    "Password corrente è un campo obbligatorio"
  ),
  password: Yup.string()
    .required("Nuova password è un campo obbligatorio")
    .oneOf(
      [Yup.ref("confirm_password"), null],
      "Le due password non sono uguali"
    ),
  confirm_password: Yup.string()
    .required("Conferma password è un campo obbligatorio")
    .oneOf([Yup.ref("password"), null], "Le due password non sono uguali"),
})

export function ChangePasswordModal({
  loading,
  isOpen,
  onClosed,
  onSubmit,
  onCancel,
  success,
}) {
  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions)
    },
  })

  return (
    <Modal show={isOpen} centered onHide={onCancel} onExited={onClosed}>
      <Modal.Header closeButton>Modifica Password</Modal.Header>
      <Modal.Body>
        <div>
          <Form.Group>
            <Form.Label>Password corrente</Form.Label>
            <Form.Control
              value={formik.values.old_password}
              name="old_password"
              className={
                formik.errors.old_password &&
                formik.touched.old_password &&
                "input-error"
              }
              type={"password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.old_password && formik.touched.old_password && (
              <span className="text-danger mt-1">
                {formik.errors.old_password}
              </span>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Nuova Password</Form.Label>
            <Form.Control
              value={formik.values.password}
              name="password"
              className={
                formik.errors.password &&
                formik.touched.password &&
                "input-error"
              }
              type={"password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.password && formik.touched.password && (
              <span className="text-danger mt-1">{formik.errors.password}</span>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Conferma Password</Form.Label>
            <Form.Control
              value={formik.values.confirm_password}
              name="confirm_password"
              className={
                formik.errors.confirm_password &&
                formik.touched.confirm_password &&
                "input-error"
              }
              type={"password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.confirm_password &&
              formik.touched.confirm_password && (
                <span className="text-danger mt-1">
                  {formik.errors.confirm_password}
                </span>
              )}
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
        <Button
          variant="outline-primary"
          onClick={() => {
            onCancel()
          }}
        >
          Annulla
        </Button>
        <LoadingButton
          variant="primary"
          enableSuccess={success}
          pending={formik.isSubmitting}
          disabled={!formik.dirty}
          onClick={() => {
            formik.submitForm()
          }}
        >
          Conferma
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}
