import { Button } from "react-bootstrap"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useAuthActions, useAuthUser } from "use-eazy-auth"
import { Page } from "../../components/Page"
import { ChangePasswordModal } from "./ChangePasswordModal"
import { useUpdatePassword } from "../../hooks/useChangePassword"
import { transformErrorForForm } from "../../utils"
import { useState } from "react"

export function Profile() {
  const { user } = useAuthUser()
  const { logout } = useAuthActions()
  const [success, setSuccess] = useState(false)
  const [changePasswordState, changePasswordActions] = useModalTrigger()
  const [, { run: updatePassword }] = useUpdatePassword()
  return (
    <Page>
      <Page.Header titleClassName="ps-3" title={user.full_name}>
        <Button variant="primary" onClick={() => logout()}>
          Logout
        </Button>
      </Page.Header>
      <Page.Body>
        <div className="card mb-3">
          <div className="card-header bg-card-header-sky-blue d-flex align-items-center justify-content-between">
            <span>Informazioni</span>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                changePasswordActions.open("dummy")
              }}
            >
              Modifica Password
            </Button>
          </div>
          <div className="card-body">
            <p>{user.full_name}</p>
            <p>{user.email}</p>
          </div>
        </div>
      </Page.Body>
      {changePasswordState.value !== null && (
        <ChangePasswordModal
          isOpen={changePasswordState.isOpen}
          id={changePasswordState.value}
          success={success}
          onCancel={changePasswordActions.close}
          onClosed={changePasswordActions.onClosed}
          onSubmit={(values, actions) => {
            return updatePassword
              .onSuccess(() => {
                setSuccess(true)
                setTimeout(() => {
                  changePasswordActions.close()
                }, 3000)
              })
              .asPromise(values)
              .catch((err) => actions.setErrors(transformErrorForForm(err)))
          }}
        />
      )}
    </Page>
  )
}
