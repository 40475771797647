import { rj, useRunRj, useRj } from "react-rocketjump"
import { api } from "../api"
import rjList from "react-rocketjump/plugins/plainList"

const liststate = rj(rjList(), {
  effectCaller: "configured",
  effect: (token) => (filters) => {
    return api.auth(token).get("/indoor/room", filters)
  },
  computed: {
    list: "getData",
    pending: "isPending",
    error: "getError",
  },
  mutations: {
    update: rj.mutation.single({
      effect: token => (id, data) => {
        return api.auth(token).patch(`/indoor/room/${id}/`, data)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.map(item => item.id === result.id ? result : item)
        }
      }
    })
  }
})

export function useIndoorRoomList(params) {
  return useRunRj(liststate, [params], false)
}

const createstate = rj({
  effectCaller: "configured",
  effect: (token) => (data) => {
    return api.auth(token).post("/indoor/room/", data)
  },
})

export function useIndoorRoomCreate() {
  return useRj(createstate)
}

const detailstate = rj({
  effectCaller: "configured",
  effect: (token) => (id) => {
    return api.auth(token).get(`/indoor/room/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/indoor/room/${id}`, data)
      },
      updater: "updateData",
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => {
        return api.auth(token).delete(`/indoor/room/${id}`)
      },
      updater: (state, result) => ({ ...state, data: null }),
    }),
  },
})

export function useIndoorRoom(id) {
  return useRunRj(detailstate, [id], true)
}
