import { useState } from "react"
import { BsEye, BsEyeSlash } from "react-icons/bs"

export function IconToggle({ map, layerName }) {
  const [visible, setVisible] = useState(map.getLayer(layerName).getVisible())

  const Cmp = visible ? BsEye : BsEyeSlash

  return (
    <Cmp
      className="text-primary small pointer"
      onClick={() => {
        setVisible(!visible)
        map.toggleLayer(layerName)
      }}
    />
  )
}
