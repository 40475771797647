import { rj, useRj } from "react-rocketjump"
import { api } from "../api"

const UpdatePasswordRj = rj({
  effectCaller: "configured",
  effect: (token) => (data) => {
    return api.auth(token).put("/userbase/change-password/", data)
  },
})

export function useUpdatePassword() {
  return useRj(UpdatePasswordRj)
}
