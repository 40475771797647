import { Button, Modal, Spinner } from "react-bootstrap"

export function DestroyMapModal({
  loading,
  isOpen,
  onClosed,
  onSubmit,
  onCancel,
}) {
  return (
    <Modal show={isOpen} centered onHide={onCancel} onExited={onClosed}>
      <Modal.Header closeButton>Elimina</Modal.Header>
      <Modal.Body>
        <p>
          Sei sicuro di voler eliminare la mappa?
          <br />
          Questa operazione è <b>irreversibile</b>
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
        <Button
          variant="outline-danger"
          onClick={() => {
            onCancel()
          }}
        >
          Annulla
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onSubmit()
          }}
        >
          {loading && <Spinner size="sm" className="me-1" />}
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
