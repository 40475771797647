import { range } from "lodash"
import { Pagination } from "react-bootstrap"

function SimplePagination({ page, numPages, onPageChange }) {
  return range(1, 1 + numPages).map((n) => (
    <Pagination.Item key={n} active={n === page} onClick={() => onPageChange(n)}>
      {n}
    </Pagination.Item>
  ))
}

function ComplexPagination({ page, numPages, onPageChange }) {
  let visibleRange = []
  let showHead = false,
    showTail = false
  if (page <= 4) {
    visibleRange = range(1, 6)
    showTail = true
  } else if (page > numPages - 4) {
    visibleRange = range(numPages - 4, numPages + 1)
    showHead = true
  } else {
    visibleRange = range(page - 2, page + 2 + 1)
    showHead = showTail = true
  }
  return (
    <>
      {showHead && <Pagination.Ellipsis />}
      {visibleRange.map((n) => (
        <Pagination.Item active={n === page} key={n} onClick={() => onPageChange(n)}>
          {n}
        </Pagination.Item>
      ))}
      {showTail && <Pagination.Ellipsis />}
    </>
  )
}

export function SmartPagination({ page, numPages, onPageChange }) {
  numPages = Math.max(numPages, 1)
  return (
    <Pagination>
      <Pagination.First
        onClick={() => {
          onPageChange(1)
        }}
      />
      {numPages > 9 && (
        <ComplexPagination
          page={page}
          numPages={numPages}
          onPageChange={onPageChange}
        />
      )}
      {numPages <= 9 && (
        <SimplePagination
          page={page}
          numPages={numPages}
          onPageChange={onPageChange}
        />
      )}
      <Pagination.Last
        onClick={() => {
          onPageChange(numPages)
        }}
      />
    </Pagination>
  )
}
