import classNames from "classnames"
import { ListGroup, Navbar } from "react-bootstrap"
import { BsMapFill, BsPeopleFill, BsPersonFill } from "react-icons/bs"
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom"

function ListGroupLink({ to = "", bgSkyBlue = false, children, ...props }) {
  const path = useResolvedPath(to)
  const match = useMatch({ path: path.pathname, end: false }) !== null

  return (
    <ListGroup.Item
      {...props}
      as={Link}
      to={to}
      className={classNames({
        "bg-blue-light": match,
        "bg-white": !match && !bgSkyBlue,
        "bg-blue-sky": bgSkyBlue,
        "text-dark-blue": bgSkyBlue,
        "border-top": bgSkyBlue,
      })}
    >
      {children}
    </ListGroup.Item>
  )
}

export function Layout() {
  return (
    <div
      style={{ height: "100vh", overflow: "hidden" }}
      className="d-flex flex-column justify-content-start align-items-stretch"
    >
      <Navbar className="p-0 bg-blue">
        <Navbar.Brand
          className="text-white px-3 py-2 m-0 title-brand"
          style={{ width: (2 / 12) * 100 + "%" }}
        >
          Beacon Maps Manager
        </Navbar.Brand>
      </Navbar>
      <div className="flex-1 d-flex flex-column justify-content-start align-items-start">
        <div className="flex-1 w-100 d-flex flex-row justify-content-start align-items-stretch">
          <div
            className="bg-blue-sky position-relative"
            style={{ width: (2 / 12) * 100 + "%" }}
          >
            <ListGroup variant="flush" className="h-100">
              <ListGroupLink to="/spazio-fisico/mappe">
                <BsMapFill />
                <span className="mx-2">Mappe antenne</span>
              </ListGroupLink>
              <ListGroupLink to="/users">
                <BsPeopleFill />
                <span className="mx-2">Utenti</span>
              </ListGroupLink>
              <div className="flex-1" />
              <ListGroupLink to="/profile">
                <BsPersonFill />
                <span className="mx-2">Profilo</span>
              </ListGroupLink>
            </ListGroup>
          </div>
          <div
            style={{ width: (10 / 12) * 100 + "%", overflowY: "auto" }}
            className="d-flex flex-column justify-content-start align-items-stretch"
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}
