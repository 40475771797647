import classNames from "classnames"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useEffect, useMemo, useRef, useState } from "react"
import { Button, ListGroup, Spinner } from "react-bootstrap"
import {
  BsArrowDownCircle,
  BsArrowDownCircleFill,
  BsEye,
  BsPencilFill,
  BsPlus,
} from "react-icons/bs"
import { deps } from "react-rocketjump"
import { useNavigate, useParams } from "react-router-dom"
import { Page } from "../../components/Page"
import {
  useIndoorBeaconCreate,
  useIndoorBeaconList,
} from "../../hooks/useIndoorBeacon"
import { useIndoorMap } from "../../hooks/useIndoorMap"
import {
  useIndoorRoomCreate,
  useIndoorRoomList,
} from "../../hooks/useIndoorRoom"
import { DestroyMapModal } from "./DestroyMapModal"
import { IconToggle } from "./IconToggle"
import { PickImageModal } from "./PickImageModal"
import { ImperativeMap } from "./imperativeMap"

export function IndoorMap() {
  const { id: floorId } = useParams()

  const nestedEntitiesFilter = useMemo(
    () => ({
      floor: floorId,
    }),
    [floorId]
  )

  const [
    { data: floor, updating: updatingFloor },
    { update: updateFloor, destroy },
  ] = useIndoorMap(deps.maybe(floorId))
  const [{ list: rooms }, { insertItem: insertRoom, update: updateRoom }] =
    useIndoorRoomList(nestedEntitiesFilter)
  const [, { run: createRoom }] = useIndoorRoomCreate()
  const [destroyModalState, destroyModalActions] = useModalTrigger()
  const [
    { list: beacons },
    {
      insertItem: insertBeacon,
      update: updateBeacon,
      updateBatch: updateBeaconBatch,
    },
  ] = useIndoorBeaconList(nestedEntitiesFilter)
  const [, { run: createBeacon }] = useIndoorBeaconCreate()

  const [pickImageModalState, pickImageModalActions] = useModalTrigger()
  const navigate = useNavigate()

  const container = useRef()
  const [map, setMap] = useState(null)

  const [mode, setMode] = useState(null)
  const [expand, setExpand] = useState(null)

  const [limitDidDraw, setLimitDidDraw] = useState(false)
  const [limitDrawExit, setLimitDrawExit] = useState(null)

  const [roomDidDraw, setRoomDidDraw] = useState(false)
  const [roomDrawExit, setRoomDrawExit] = useState(null)

  const [beaconDidDraw, setBeaconDidDraw] = useState(false)
  const [beaconDrawExit, setBeaconDrawExit] = useState(null)

  const [voronoiDrawExit, setVoronoiDrawExit] = useState(false)

  const [activeRoom, setActiveRoom] = useState(null)
  const [activeBeacon, setActiveBeacon] = useState(null)

  useEffect(() => {
    if (floor && rooms && beacons && !map) {
      setMap(new ImperativeMap(container))
    }
  }, [beacons, floor, map, rooms])

  useEffect(() => {
    if (floor && rooms && beacons && map) {
      if (floor.background_image) {
        map.setBackgroundImage(
          floor.background_image,
          floor.background_width,
          floor.background_height
        )
      }
      if (floor.boundary) {
        map.setLimit(floor.boundary)
      }
      if (rooms.length) {
        map.setRooms(rooms)
      }
      if (beacons.length) {
        map.setBeacons(beacons)
      }
    }
  }, [beacons, floor, rooms, map])

  if (!floor || !rooms || !beacons) {
    return (
      <div className="d-flex h-100 w-100 align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  return (
    <Page sidebar>
      <Page.Header title={floor.name} titleClassName="ps-3">
        <Button
          variant="danger"
          onClick={() => {
            destroyModalActions.open("dummy")
          }}
        >
          Elimina
        </Button>
      </Page.Header>
      <Page.Body>
        <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-stretch">
          <div className="flex-1 bg-light" ref={container}></div>
          <div className="pt-3" style={{ height: 200 }}>
            {mode === "limit" && (
              <div className="d-flex flex-row justify-content-between align-items-center">
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setMode(null)
                    limitDrawExit.reject()
                  }}
                >
                  Annulla
                </Button>
                <Button
                  variant="primary"
                  disabled={!limitDidDraw}
                  onClick={() => {
                    setMode(null)
                    const geom = limitDrawExit.commit()
                    updateFloor(floorId, {
                      boundary: geom,
                    })
                  }}
                >
                  Conferma
                </Button>
              </div>
            )}
            {mode === "rooms" && activeRoom !== null && (
              <div>
                <input
                  className="form-control w-50"
                  type="text"
                  value={activeRoom.name}
                  onChange={(e) =>
                    setActiveRoom((r) => ({ ...r, name: e.target.value }))
                  }
                />
                <div className="d-flex flex-row justify-content-between align-items-center mt-4">
                  <div>
                    <Button
                      className="me-4"
                      variant="outline-primary"
                      onClick={() => {
                        setMode(null)
                        setActiveRoom(null)
                        roomDrawExit.reject()
                      }}
                    >
                      Annulla
                    </Button>
                    <Button
                      variant="primary"
                      disabled={!activeRoom.name || !roomDidDraw}
                      onClick={() => {
                        const geom = roomDrawExit.commit()
                        if (!activeRoom.id) {
                          createRoom
                            .onSuccess((room) => {
                              insertRoom(room)
                              setMode(null)
                              setActiveRoom(null)
                            })
                            .run({
                              name: activeRoom.name,
                              geometry: geom,
                              floor: floorId,
                            })
                        } else {
                          updateRoom
                            .onSuccess((room) => {
                              setMode(null)
                              setActiveRoom(null)
                            })
                            .run(activeRoom.id, {
                              name: activeRoom.name,
                              geometry: geom,
                            })
                        }
                      }}
                    >
                      Conferma
                    </Button>
                  </div>
                  {activeRoom?.id && (
                    <Button variant="danger" onClick={() => {}}>
                      Elimina
                    </Button>
                  )}
                </div>
              </div>
            )}
            {mode === "rooms" && activeRoom === null && (
              <div>
                <p>Seleziona una stanza</p>
              </div>
            )}

            {mode === "beacons" && activeBeacon !== null && (
              <div>
                <input
                  className="form-control w-50"
                  type="number"
                  step="1"
                  value={activeBeacon.minor}
                  onChange={(e) =>
                    setActiveBeacon((b) => ({
                      ...b,
                      minor: parseInt(e.target.value, 10),
                    }))
                  }
                />
                <div className="d-flex flex-row justify-content-between align-items-center mt-4">
                  <div>
                    <Button
                      className="me-4"
                      variant="outline-primary"
                      onClick={() => {
                        setMode(null)
                        setActiveBeacon(null)
                        beaconDrawExit.reject()
                      }}
                    >
                      Annulla
                    </Button>
                    <Button
                      variant="primary"
                      disabled={!activeBeacon.minor || !beaconDidDraw}
                      onClick={() => {
                        const geom = beaconDrawExit.commit()
                        if (!activeBeacon.id) {
                          createBeacon
                            .onSuccess((room) => {
                              insertBeacon(room)
                              setActiveBeacon(null)
                              setMode(null)
                            })
                            .run({
                              minor: activeBeacon.minor,
                              geometry: geom,
                              floor: floorId,
                            })
                        } else {
                          updateBeacon
                            .onSuccess((room) => {
                              setMode(null)
                              setActiveBeacon(null)
                            })
                            .run(activeBeacon.id, {
                              minor: activeBeacon.minor,
                              geometry: geom,
                            })
                        }
                      }}
                    >
                      Conferma
                    </Button>
                  </div>
                  {activeBeacon?.id && (
                    <Button variant="danger" onClick={() => {}}>
                      Elimina
                    </Button>
                  )}
                </div>
              </div>
            )}
            {mode === "beacons" && activeBeacon === null && (
              <div>
                <p>Seleziona un'antenna</p>
              </div>
            )}
            {mode === "voronoi" && (
              <div className="d-flex flex-row justify-content-between align-items-center">
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setMode(null)
                    voronoiDrawExit.reject()
                  }}
                >
                  Annulla
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setMode(null)
                    const result = voronoiDrawExit.commit()
                    updateBeaconBatch(result)
                  }}
                >
                  Conferma
                </Button>
              </div>
            )}
          </div>
        </div>
      </Page.Body>
      <Page.SideBar>
        <div style={{ paddingTop: 90 }} />
        {map && (
          <ListGroup variant="flush">
            <ListGroup.Item className="bg-light border-dedede border-bottom">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span>
                  <BsArrowDownCircleFill className="me-2 invisible" />
                  Sfondo
                  <BsPencilFill
                    className="text-primary pointer small ms-2"
                    onClick={() => {
                      pickImageModalActions.open("dummy")
                    }}
                  />
                </span>
                <IconToggle map={map} layerName="background" />
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="bg-light border-dedede border-bottom">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span>
                  <BsArrowDownCircleFill className="me-2 invisible" />
                  Limite
                  <BsPencilFill
                    className="text-primary pointer small ms-2"
                    onClick={() => {
                      setMode("limit")
                      setLimitDrawExit(
                        map.enableLimitDraw((update) =>
                          setLimitDidDraw(update.allowCommit)
                        )
                      )
                    }}
                  />
                </span>
                <IconToggle map={map} layerName="limit" />
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="bg-light border-dedede border-bottom">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span>
                  <BsArrowDownCircleFill className="me-2" />
                  Scala
                  <BsPencilFill className="text-primary pointer small ms-2" />
                </span>
                <BsEye className="text-primary small pointer" />
              </div>
            </ListGroup.Item>

            <ListGroup.Item className="bg-light border-dedede border-bottom">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span>
                  {expand === "rooms" && (
                    <BsArrowDownCircle
                      className="me-2 pointer"
                      onClick={() => setExpand(null)}
                    />
                  )}
                  {expand !== "rooms" && (
                    <BsArrowDownCircleFill
                      className="me-2 pointer"
                      onClick={() => setExpand("rooms")}
                    />
                  )}
                  Stanze
                  <BsPlus
                    className="text-primary pointer small ms-2"
                    onClick={() => {
                      setMode("rooms")
                      setActiveRoom({ name: "" })
                      setRoomDidDraw(false)
                      setRoomDrawExit(
                        map.enableRoomDraw((update) => {
                          setRoomDidDraw(update.allowCommit)
                        })
                      )
                    }}
                  />
                  <BsPencilFill
                    className="text-primary pointer small ms-2"
                    onClick={() => {
                      if (mode === "rooms") {
                        if (activeRoom) {
                          setActiveRoom(null)
                          roomDrawExit.reject()
                        }
                        setMode(null)
                      } else {
                        setMode("rooms")
                        setExpand("rooms")
                      }
                    }}
                  />
                </span>
                <IconToggle map={map} layerName="rooms" />
              </div>
              {expand === "rooms" && (
                <div>
                  {rooms.map((room) => (
                    <div key={room.id}>
                      {mode !== "rooms" && <span>{room.name}</span>}
                      {mode === "rooms" && (
                        <u
                          className={classNames("pointer", {
                            "text-info": room.id === activeRoom?.id,
                          })}
                          onClick={() => {
                            if (activeRoom !== null) {
                              roomDrawExit.reject()
                            }
                            setActiveRoom(room)
                            setRoomDidDraw(false)
                            setRoomDrawExit(
                              map.enableRoomDraw((update) => {
                                setRoomDidDraw(update.allowCommit)
                              }, room.id)
                            )
                          }}
                        >
                          {room.name}
                        </u>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </ListGroup.Item>

            <ListGroup.Item className="bg-light border-dedede border-bottom">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span>
                  {expand === "beacons" && (
                    <BsArrowDownCircle
                      className="me-2 pointer"
                      onClick={() => setExpand(null)}
                    />
                  )}
                  {expand !== "beacons" && (
                    <BsArrowDownCircleFill
                      className="me-2 pointer"
                      onClick={() => setExpand("beacons")}
                    />
                  )}
                  Beacon
                  <BsPlus
                    className="text-primary pointer small ms-2"
                    onClick={() => {
                      setMode("beacons")
                      setActiveBeacon({ minor: "" })
                      setBeaconDidDraw(false)
                      setBeaconDrawExit(
                        map.enableBeaconDraw((update) => {
                          setBeaconDidDraw(update.allowCommit)
                        })
                      )
                    }}
                  />
                  <BsPencilFill
                    className="text-primary pointer small ms-2"
                    onClick={() => {
                      if (mode === "beacons") {
                        if (activeBeacon) {
                          setActiveBeacon(null)
                          beaconDrawExit.reject()
                        }
                        setMode(null)
                      } else {
                        setMode("beacons")
                        setExpand("beacons")
                      }
                    }}
                  />
                </span>
                <IconToggle map={map} layerName="beacons" />
              </div>
              {expand === "beacons" && (
                <div>
                  {beacons.map((beacon) => (
                    <div key={beacon.id}>
                      {mode !== "beacons" && <span>{beacon.minor}</span>}
                      {mode === "beacons" && (
                        <u
                          className={classNames("pointer", {
                            "text-info": beacon.id === activeBeacon?.id,
                          })}
                          onClick={() => {
                            if (activeBeacon !== null) {
                              beaconDrawExit.reject()
                            }
                            setActiveBeacon(beacon)
                            setBeaconDidDraw(false)
                            setBeaconDrawExit(
                              map.enableBeaconDraw((update) => {
                                setBeaconDidDraw(update.allowCommit)
                              }, beacon.id)
                            )
                          }}
                        >
                          {beacon.minor}
                        </u>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </ListGroup.Item>

            <ListGroup.Item className="bg-light border-dedede border-bottom">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span>
                  <BsArrowDownCircleFill className="me-2 invisible" />
                  Voronoi
                  <BsPencilFill
                    className="text-primary pointer small ms-2"
                    onClick={() => {
                      setMode("voronoi")
                      setVoronoiDrawExit(map.enableVoronoiDraw())
                    }}
                  />
                </span>
                <IconToggle map={map} layerName="voronoi" />
              </div>
            </ListGroup.Item>
          </ListGroup>
        )}
      </Page.SideBar>
      {pickImageModalState.value && (
        <PickImageModal
          loading={updatingFloor}
          isOpen={pickImageModalState.isOpen}
          onClosed={pickImageModalActions.onClosed}
          onCancel={pickImageModalActions.close}
          onConfirm={(file) => {
            const fd = new FormData()
            fd.append("background_image", file)
            updateFloor
              .onSuccess(() => {
                pickImageModalActions.close()
              })
              .run(floorId, fd)
          }}
        />
      )}
      {destroyModalState.value !== null && (
        <DestroyMapModal
          isOpen={destroyModalState.isOpen}
          id={destroyModalState.value}
          onCancel={destroyModalActions.close}
          onClosed={destroyModalActions.onClosed}
          onSubmit={(result) => {
            destroy
              .onSuccess(() => {
                destroyModalActions.close()
                navigate("/spazio-fisico/mappe", { replace: true })
              })
              .run(floor.id)
          }}
        />
      )}
    </Page>
  )
}
