import { rj, useRunRj, useRj } from "react-rocketjump"
import { api } from "../api"
import rjList from "react-rocketjump/plugins/plainList"
import { useEffect } from "react"

const liststate = rj(rjList(), {
  effectCaller: "configured",
  effect: (token) => (filters) => {
    return api.auth(token).get("/indoor/floor", filters)
  },
  computed: {
    list: "getData",
    pending: "isPending",
    error: "getError",
  },
})

export function useIndoorMapList(params) {
  return useRunRj(liststate, [params], false)
}

const createstate = rj({
  effectCaller: "configured",
  effect: (token) => (data) => {
    return api.auth(token).post("/indoor/floor/", data)
  },
})

export function useIndoorMapCreate() {
  return useRj(createstate)
}

const detailstate = rj({
  effectCaller: "configured",
  effect: (token) => (id) => {
    return api.auth(token).get(`/indoor/floor/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/indoor/floor/${id}`, data)
      },
      updater: "updateData",
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => {
        return api.auth(token).delete(`/indoor/floor/${id}`)
      },
      updater: (state, result) => ({ ...state, data: null }),
    }),
  },
  computed: {
    data: "getData",
    error: "getError",
    pending: "isPending",
    updating: (state) => state.mutations.update.pending,
  },
})

export function useIndoorMap(id) {
  return useRunRj(detailstate, [id], true)
}

const publishstate = rj({
  effectCaller: "configured",
  effect: (token) => () => {
    return api.auth(token).get(`/indoor/publish`)
  },
  mutations: {
    publishItems: rj.mutation.single({
      effect: (token) => () => {
        return api.auth(token).post(`/indoor/publish`)
      },
      updater: "updateData",
    }),
  },
})

export function useIndoorPublish() {
  const [state, actions] = useRunRj(publishstate)

  useEffect(() => {
    const interval = setInterval(actions.run, 3000)
    return () => {
      clearInterval(interval)
    }
  },[actions])
  return [state, actions]
}
