import { BrowserRouter, useRoutes } from "react-router-dom"
import Auth from "./Auth"
import { NAVIGATION } from "./navigation"

function Content() {
  return useRoutes(NAVIGATION)
}

function App() {
  return (
    <Auth>
      <BrowserRouter>
        <Content />
      </BrowserRouter>
    </Auth>
  )
}

export default App
