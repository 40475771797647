import { Badge, Button } from "react-bootstrap"

export function Page({ children, sidebar = false }) {
  return (
    <div
      className="flex-1 d-flex flex-column justify-content-start align-items-stretch position-relative"
      style={{ paddingRight: sidebar ? 240 : 0 }}
    >
      {children}
    </div>
  )
}

function PageHeader({
  title,
  children,
  previewState,
  titleClassName = "",
  showPublication,
}) {
  return (
    <div
      className="px-3 d-flex flex-row justify-content-between align-items-center"
      style={{ paddingTop: 30, paddingBottom: 40 }}
    >
      <div className={titleClassName}>
        <p className="m-0 d-flex flex-row align-items-center">
          <b className="title-page">{title}</b>
          {previewState === "🔴" && (
            <Badge className="ms-2" bg="danger">
              Preview non disponibile
            </Badge>
          )}
          {previewState === "🟡" && (
            <Badge className="ms-2" bg="warning">
              Preview in attesa
            </Badge>
          )}
          {previewState === "🟢" && (
            <Badge className="ms-2" bg="success">
              Preview disponibile
            </Badge>
          )}
        </p>
      </div>
      {showPublication && <div>{showPublication}</div>}
      <div className="d-flex flex-row justify-content-end align-items-center">
        {children}
      </div>
    </div>
  )
}

function PageHeaderAction({ title, onClick, variant = "primary", ...props }) {
  return (
    <Button variant={variant} onClick={onClick} {...props}>
      <span className="d-inline-flex align-items-center">{title}</span>
    </Button>
  )
}

function PageBody({ children }) {
  return <div className="px-3 flex-1 overflow-auto">{children}</div>
}

function PageSideBar({ children }) {
  return (
    <div
      className="position-absolute bg-light"
      style={{ top: 0, bottom: 0, right: 0, width: 240 }}
    >
      {children}
    </div>
  )
}

PageHeader.Action = PageHeaderAction
Page.Header = PageHeader
Page.Body = PageBody
Page.SideBar = PageSideBar
