import { Navigate } from "react-router-dom"
import { Layout } from "./components/Layout"
import { RequireAuth, RequireGuest } from "./lib"
import { IndoorMap } from "./pages/IndoorMap"
import { IndoorMapList } from "./pages/IndoorMapList"
import { Login } from "./pages/Login"
import { NotFound } from "./pages/NotFound"
import { Profile } from "./pages/Profile/Profile"
import { UserDetail } from "./pages/UserDetail"
import { UsersList } from "./pages/UsersList"

function guest(Component) {
  return (
    <RequireGuest>
      <Component />
    </RequireGuest>
  )
}

function auth(Component, props) {
  return (
    <RequireAuth>
      <Component {...props} />
    </RequireAuth>
  )
}

export const NAVIGATION = [
  {
    path: "login",
    element: guest(Login),
  },
  {
    path: "spazio-fisico",
    element: <Layout />,
    breadcrumb: "Spazio Fisico",
    breadcrumbLink: false,
    children: [
      {
        path: "mappe",
        breadcrumb: "Mappe antenne",
        children: [
          {
            index: true,
            element: auth(IndoorMapList),
          },
          {
            path: ":id",
            breadcrumb: ({ id }) => id,
            breadcrumbLink: false,
            element: auth(IndoorMap),
          },
        ],
      },
    ],
  },
  {
    path: "profile",
    element: <Layout />,
    breadcrumb: "Profilo",
    breadcrumbLink: false,
    children: [
      {
        index: true,
        element: auth(Profile),
      },
    ],
  },
  {
    path: "users",
    element: <Layout />,
    breadcrumb: "Utenti",
    breadcrumbLink: false,
    children: [
      {
        path: "",
        breadcrumb: "Utenti Cms",
        children: [
          {
            index: true,
            element: auth(UsersList),
          },
          {
            path: ":id",
            breadcrumb: ({ id }) => id,
            breadcrumbLink: false,
            element: auth(UserDetail),
          },
          {
            path: "new",
            breadcrumb: "Nuovo utente",
            breadcrumbLink: false,
            element: auth(UserDetail),
          },
        ],
      },
    ],
  },
  {
    index: true,
    element: (
      <RequireAuth>
        <Navigate to="/spazio-fisico/mappe" />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <NotFound />,
      },
    ],
  },
]
