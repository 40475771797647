import { deps, rj, useRunRj } from "react-rocketjump"
import { api } from "../api"
import rjList, {
    nextPreviousPaginationAdapter,
  } from "react-rocketjump/plugins/list"
  import { PAGE_SIZE } from "../constants"

const liststate = rj(
    rjList({
      pageSize: PAGE_SIZE,
      pagination: nextPreviousPaginationAdapter,
    }),
    {
      effectCaller: "configured",
      effect: (token) => (filters) => {
        return api.auth(token).get("/userbase/users", filters)
      },
      computed: {
        list: "getList",
        pagination: "getPagination",
        numPages: "getNumPages",
        pending: "isPending",
        error: "getError",
      },
    }
  )

export function useUsersList(params) {
  return useRunRj(liststate, [params], false)
}

const detailstate = rj({
  effectCaller: "configured",
  effect: (token) => (id) => {
    return api.auth(token).get(`/userbase/users/${id}`)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) => {
        return api.auth(token).post(`/userbase/users`, data)
      },
      updater: "updateData",
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/userbase/users/${id}`, data)
      },
      updater: "updateData",
    }),
    destroy: {
      effect: (token) => (id) => {
        return api.auth(token).delete(`/userbase/users/${id}`)
      },
      updater: (state, result) => ({ ...state, data: null }),
    },
    resendMail: {
      effect: (token) => (id) => {
        return api.auth(token).post(`/userbase/users/${id}/resend_activation_email`)
      },
      updater: "updateData",
    }
  },
})

export function useUserDetail(id) {
  return useRunRj(detailstate, [deps.maybe(id)], true)
}
