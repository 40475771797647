import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useMemo } from "react"
import { Spinner, Table } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Page } from "../../components/Page"
import { SmartPagination } from "../../components/SmartPagination"
import { useIndoorMapCreate, useIndoorMapList } from "../../hooks/useIndoorMap"
import { CreateIndoorMapModal } from "./CreateIndoorMapModal"

export function IndoorMapList() {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = parseInt(searchParams.get("page") || 1, 10)

  const listFilter = useMemo(() => {
    return {
      page: page ?? 1,
    }
  }, [page])

  const [{ list, numPages }] = useIndoorMapList(listFilter)

  const [{ pending: creatingIndoorMap }, { run: createIndoorMap }] =
    useIndoorMapCreate()
  const navigate = useNavigate()

  const [createModalState, createModalActions] = useModalTrigger()

  if (list === null) {
    return (
      <div className="d-flex h-100 w-100 align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  return (
    <Page>
      <Page.Header title="Mappe" titleClassName="ps-3">
        <Page.Header.Action
          title="Crea"
          onClick={() => {
            createModalActions.open("dummy")
          }}
        />
      </Page.Header>
      <Page.Body>
        {list?.length > 0 && (
          <Table
            striped
            borderless
            style={{
              tableLayout: "fixed",
              "--bs-table-striped-bg": "var(--bs-light)",
            }}
          >
            <thead>
              <tr>
                <th className="ps-3" style={{ width: "34%" }}>
                  Mappa
                </th>
                <th style={{ width: "33%" }}>Stanze</th>
                <th style={{ width: "33%" }}>Beacon</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((floor) => (
                <tr key={floor.id}>
                  <td className="ps-3">
                    <Link to={`/spazio-fisico/mappe/${floor.id}`}>
                      {floor.name}
                    </Link>
                  </td>
                  <td>{floor.room_count}</td>
                  <td>{floor.beacon_count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {list?.length === 0 && (
          <p className="text-center" style={{ marginTop: "30vh" }}>
            <i>Nessuna mappa presente</i>
          </p>
        )}
        {list?.length > 0 && (
          <div className="d-flex flex-row justify-content-end">
            <SmartPagination
              page={page}
              numPages={numPages}
              onPageChange={(page) => setSearchParams({ page })}
            ></SmartPagination>
          </div>
        )}
        {createModalState.value && (
          <CreateIndoorMapModal
            loading={creatingIndoorMap}
            isOpen={createModalState.isOpen}
            onClosed={createModalActions.onClosed}
            onSubmit={(name) => {
              createIndoorMap
                .onSuccess((result) => {
                  createModalActions.close()
                  navigate(`/spazio-fisico/mappe/${result.id}`)
                })
                .run({ name })
            }}
            onCancel={createModalActions.close}
          />
        )}
      </Page.Body>
    </Page>
  )
}
